import { useState } from "react";
import { Item } from "../../../state/reducers/apartmentReducer"
import { Area } from "./Area"
import { AdDate } from "./AdDate"
import { Description } from "./Description"
import { Price } from "./Price"
import { Title } from "./Title"
import { Pictures } from "./Pictures"
import { filter } from "../../Filter/Content"
import { useDispatch, useSelector } from "react-redux"
import { actionsCreators, State } from "../../../state"
import { useSearchParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import ListingCarousel from './ListindDetailsModal/ListingCarousel';

export interface Props {
    item: Item,
    counter: number
};

export const Listing: React.FC<Props> = ({ item, counter }: Props) => {

    const filterState = useSelector((state: State) => state.filter);
    const [displayedPictures, setDisplayedPictures] = useState<string[]>([]);

    const link = (): string => {
        filterState.stateOfFilter.pageNumber = "1";
        return `/?sortiranje=${filterState.stateOfFilter.sortiranje}&pageNumber=${filterState.stateOfFilter.pageNumber}&duplicate_group=${item.duplicateGroup}`
    }

    return (
        <div className="col-lg-4 col-md-6 col-sm-12 dark apartment-column">
            {
                displayedPictures.length > 0 && 
                <div className='picture-wrapper'>
                    <ListingCarousel images={displayedPictures} isOpen={true} setDipslayedPictures={setDisplayedPictures}></ListingCarousel>
                </div>
            }
            <div className="apartment-container" style={{ border: "1px solid #384885" }} >
                <div className="container-fluid">
                    <div className="card-img" style={{ maxHeight: "280px" }}>
                        <Pictures item={item} counter={counter} setDipslayedPictures={setDisplayedPictures} />
                    </div>
                    <Title item={item} counter={counter} link={link()} />
                    <div style={{textAlign:"center"}}>
                        {item.duplicateGroup !== null && !filterState.stateOfFilter.duplicate_group &&
                            <p className="text-muted ml-3" style={{ fontSize: "14px" }}>(postoji više istih oglasa)</p>}
                    </div>
                    <Price item={item} counter={counter} />
                    <Area item={item} counter={counter} />
                    <Description item={item} counter={counter} />
                    <AdDate item={item} counter={counter} />
                </div>
            </div>
        </div>
    )
}