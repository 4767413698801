import React, { useEffect, useState } from 'react'
import { Option } from "../../state/reducers/filterReducer";
import AddListingForm from './AddListingForm';
import { useParams } from 'react-router-dom';
import { GetById } from '../../services/listingService';
import { Item } from '../../state/reducers/apartmentReducer';



export interface stepSectionProps {
  onNext?: Function
  onPrevious?: () => void
  selectedType?: Option
}

interface Step {
  order: number,
  name: string
}

function AddListingSection() {

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedType, setSelectedType] = useState<Option>();
  const params = useParams();

  const incrementStep = (option: Option) => {
    if (currentStep === steps.length) {
      return;
    }
    setCurrentStep(currentStep + 1);
    setSelectedType(option);
  }

  const decrementStop = () => {
    if (currentStep === 1) {
      return;
    }
    setCurrentStep(currentStep - 1);
  }

  useEffect(() => {
    if(params.id){
      incrementStep({value:"", optionContent:""});
    }
  }, []);

  const steps: Step[] = [{ order: 1, name: "Tip oglasa" }, { order: 2, name: "Podaci" }]
  return (
    <div className=' p-20 d-flex flex-column justify-content-center align-items-center'>
      <div className='steps-container'>
        {steps.map(step => {
          return (
            <span key={step.name} className={'step'}>
              <span className={'step-circle' + `${step.order === currentStep ? ' step-active' : ""}`}>
                {step.order}
              </span>
              <p className='step-name'>{step.name}</p>
            </span>
          )
        })}
      </div>
      {currentStep === 1 && <ChooseListingTypeSection onNext={incrementStep}></ChooseListingTypeSection>}
      {currentStep === 2 && <AddListingForm onPrevious={decrementStop} selectedType={selectedType}></AddListingForm>}
    </div>
  )
}

function ChooseListingTypeSection({ onNext }: stepSectionProps) {
  const types: Option[] = [
    {
      value: "apartment",
      optionContent: "Stan"
    },
    {
      value: "house",
      optionContent: "Kuća"
    },
    {
      value: "commercial",
      optionContent: "Polsovni prostor"
    },
    {
      value: "land",
      optionContent: "Zemljište"
    },
    {
      value: "garage",
      optionContent: "Garaža"
    },
  ];

  return (
    <div className='text-center d-flex flex-column'>
      <h3 className='form-title'>Šta oglašavate?</h3>
      <div className='grid grid-3-col gap-24 max-content p-20'>
        {types.map(option => {
          return (
            <button key={option.value} className='square-card' onClick={() => { onNext!(option) }}>
              <h5 className='square-card-title'>{option.optionContent}</h5>
            </button>)
        })}
      </div>
    </div>
  )
}

export default AddListingSection