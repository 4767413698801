import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { ParamsOfSearch } from "../../state/reducers/filterReducer";
import { Listing } from "./Listing/Listing";

export const Main = () => {

    const dispatch = useDispatch();
    const { loadApartments, changeFilterParams, changeListApartments } = bindActionCreators(actionsCreators, dispatch);

    const apartments = useSelector((state: State) => state.apartments);
    const filterState = useSelector((state: State) => state.filter);
    const loading = useSelector((state: State) => state.loading);

    const items = apartments.items.map((apartment,index) => {
        return (
            <Listing key={index} item={apartment} counter={index+1} />
            )
    });

    const [params] = useSearchParams();
    const page = params.get("pageNumber");
    const path = useLocation().pathname;
    const showFilter = (params.get("showFilter") === "true" && !params.get("duplicate_group")) ||
                        (!params.get("duplicate_group") && 
                        !path.includes("favoriti") && 
                        !path.includes("obrisani"));
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      }
    const  query = useQuery ();
    
    const keys = Array.from(query.keys());
    useEffect(() => {
        if(keys.length>0 && !apartments.isLoading){
            keys.forEach(key => {
                // if(key==="page"){
                //     filterState.stateOfFilter.pageNumber = query.get(key)!;
                // }else{
                    filterState.stateOfFilter[key as keyof ParamsOfSearch] = query.get(key)!;
                // }
            });

            if(!showFilter){
                filterState.stateOfFilter.tip = "";
                filterState.stateOfFilter.grad = "";
                filterState.stateOfFilter.krajevi = "";
                filterState.stateOfFilter.location = "";
                filterState.stateOfFilter.min_kvadratura = "";
                filterState.stateOfFilter.max_kvadratura = "";
                filterState.stateOfFilter.min_cena = "";
                filterState.stateOfFilter.min_soba = "";
                filterState.stateOfFilter.max_soba = "";
                filterState.stateOfFilter.akcija = "";
                filterState.stateOfFilter.duplicate_group = !path.includes("favoriti") && !path.includes("obrisani") ? filterState.stateOfFilter.duplicate_group : "";
            }
            
            apartments.isLoading = true;
            changeListApartments(apartments)
            changeFilterParams(filterState);
            loadApartments(filterState, path);
        }
        
        
    }, []);
    const loadData = (loading.isLoading && apartments.items.length===0 && page!==null) && <>
                                    <div className="sk-folding-cube" id="loader" style={{marginTop:"60px"}}>
                                        <div className="sk-cube1 sk-cube"></div>
                                        <div className="sk-cube2 sk-cube"></div>
                                        <div className="sk-cube4 sk-cube"></div>
                                        <div className="sk-cube3 sk-cube"></div>
                                    </div>
                                    <h3 id="loaderText" style={{margin:"auto",display:"table",marginBottom:"30px"}}>Učitavanje...</h3>
                                </>

    return (
        <div id="data-container">
            <div className="row">
                {items}
            </div>
            {loadData}
        </div>
        )
}