

export const SocialNetworks = () => {
    return (
        <div className="col-md-6">
            <ul className="footer_list footer_social">
                <li><a><i className="fa fa-facebook"></i></a></li>
                <li><a><i className="fa fa-twitter"></i></a></li>
                <li><a><i className="fa fa-instagram"></i></a></li>
                <li><a><i className="fa fa-linkedin"></i></a></li>
            </ul>
        </div>
        )
}