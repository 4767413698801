import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../img/logoi.jpg";
import pretraga from "../../img/pretraga.gif";
import slike from "../../img/slike.gif";
import { State, actionsCreators } from "../../state";

export const GuideModal = () => {

    const dispatch = useDispatch();
    const { isVisibleModal } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.modal);

    const [state, setState] = useState(false);

    const timer = () => {
        var counter = 7;
        let intervalId = setInterval(() => {
            counter = counter - 1;
            if(counter === 0) {
                clearInterval(intervalId);
                setState(true)
            };
        }, 1000);
    }

    useEffect(timer, []);

    return(
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Uputstvo</h4>
                        <button 
                            type="button" 
                            className="close" 
                            data-dismiss="modal"
                            onClick={() => {
                                modal.state.isVisibleGuideModal = false;
                                isVisibleModal({state : modal.state});
                            }}
                            >&times;</button>
                    </div>
                    <div className="modal-body">
                        <div id="carouselExampleControls" className="carousel slide" data-wrap="false" data-ride="carousel" data-interval="7000">
                            <div className="carousel-inner">
                                <div className={`carousel-item ${!state && 'active'}`}>
                                    <h5><b>Nadji.rs</b> je pretraživač nekretnina koji vam omogućava praktičnu, efikasnu i brzu pretragu <b>svih nekretnina u Srbiji na jednom mestu.</b></h5>
                                    <img style={{width:"100%"}} src={logo}/>
                                    <button 
                                        style={{float:"right",marginTop:"5px",backgroundColor:"var(--primary-color)"}} 
                                        className="btn btn-primary btn-sm" 
                                        onClick={() => setState(true)} 
                                        role="button" 
                                        data-slide="next">
                                        <span aria-hidden="true">Napred</span>
                                    </button>
                                </div>
                                <div className={`carousel-item ${state && 'active'}`}>
                                    <h5>Izaberite <b>izdavanje</b> ili <b>prodaju</b> i tip nekretnine. Zadajte kriterijume pretrage: lokaciju, cenu, kvadraturu i sobnost. Kriterijume pretrage koje ne želite da koristite ostavite praznim: </h5>
                                    <img style={{marginTop:"5px",width:"100%"}} src={pretraga} />
                                    <h5><b>Slike</b> nekretnina u rezultatima pretrage možete pregledati korišćenjem <b>strelica</b>:</h5>
                                    <img style={{width:"100%"}} src={slike}/>
                                    <button 
                                        style={{marginTop:"5px",backgroundColor:"var(--secondary-color)",border:"none"}} 
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            setState(false);
                                            timer();
                                        }}  
                                        role="button" data-slide="prev">
                                        <span aria-hidden="true">Nazad</span>
                                    </button>
                                    <button 
                                        type="button" 
                                        style={{width:"55px",marginTop:"5px",float:"right"}} 
                                        className="btn btn-success btn-sm" 
                                        data-dismiss="modal"
                                        onClick={() => {
                                                modal.state.isVisibleGuideModal = false;
                                                isVisibleModal({state : modal.state});
                                            }}
                                        >Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}