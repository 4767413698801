import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../../state";
import { Item } from "../../../state/reducers/apartmentReducer"
import ListingDetailsSection from "./ListindDetailsModal/ListingDetailsSection";

export interface Props {
    item: Item,
    counter: number,
    link: string
};

export const Title: React.FC<Props> = ({ item, link }: Props) => {

    const dispatch = useDispatch();
    const { isVisibleListingModal } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.listingModal);

    const filterState = useSelector((state: State) => state.filter);
    const navigate = useNavigate();
    const url = item.url.includes(",") ? item.url.split(",")[0] : item.url;

    const path = useLocation().pathname;
    const { changeFilterParams, loadApartments } = bindActionCreators(actionsCreators, dispatch);

    const handleClick = () => {
        if (item.duplicateGroup != null) {
            filterState.stateOfFilter.duplicate_group = item.duplicateGroup;
            filterState.stateOfFilter.pageNumber = "1";
            changeFilterParams(filterState);
            loadApartments(filterState, path);
        }
    }
    // `/?${filter(filterState)}&duplicate_group=${item.duplicate_group}`
    return (
        <div className="row info-row">
            <h1>
                {item.duplicateGroup !== null && !filterState.stateOfFilter.duplicate_group ? 
                    <>
                        <a
                            href={link}
                            className={"title-link"}>
                            {item.title}
                        </a>
                        {/* <span className="text-muted ml-3" style={{ fontSize: "14px" }}>(postoji više istih oglasa)</span> */}
                    </>
                    :
                    <>
                        {
                            url.includes("nadji.in.rs") && <span style={{ cursor: "pointer" }} onClick={() => {
                                modal.state.isVisibleListingModal = true;
                                isVisibleListingModal({ state: modal.state, item: item });
                            }} className="title-link">
                                {item.title}
                            </span>
                        }
                        {
                            !url.includes("nadji.in.rs") && <a href={url} target="_blank" className="title-link">
                                {item.title}
                            </a>
                        }
                    </>}

            </h1>

        </div>
    )
}