import React, { TouchEvent, useState } from 'react';
import Modal from 'react-modal';

interface ListingCarouselProps {
    images: string[];
    isOpen: boolean,
    setDipslayedPictures?: (value: string[]) => void;
}

const ListingCarousel: React.FC<ListingCarouselProps> = ({ images, isOpen, setDipslayedPictures }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen);

    const next = (): void => {

        if (activeIndex === images.length - 1) setActiveIndex(0)
        else setActiveIndex(activeIndex + 1);
    };

    const prev = (): void => {

        if (activeIndex === 0) setActiveIndex(images.length - 1)
        else setActiveIndex(activeIndex - 1);
    };

    const openModal = (): void => {
        setIsModalOpen(true);
    };
    
    const closeModal = (): void => {
        if(isOpen){
            if (setDipslayedPictures) {
                setDipslayedPictures([]);
            }
        }else{
            setIsModalOpen(false);
        }
    };

    const [touchPosition, setTouchPosition] = useState("null");
    const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown.toString());
    }
    const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        const touchDown = touchPosition;
        e.stopPropagation();

        if (touchDown === "null") {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = Number(touchDown) - currentTouch

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition("null");
    }

    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <>

            {!isOpen && <div id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
                onTouchStart={e => handleTouchStart(e)}
                onTouchMove={e => handleTouchMove(e)}>
                <div className="carousel-inner">
                    {images.map((image, index) => (
                        <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
                            <img src={image} className="d-block w-100" alt={`slide ${index}`} style={{ objectFit: "cover", height: "500px", cursor: "pointer" }} onClick={openModal} />
                            <div className="carousel-caption">
                                <h5 className='text-light' style={{ fontWeight: '700' }}>{`Slika ${index + 1}/${images.length}`}</h5>
                            </div>
                        </div>
                    ))}
                </div>
                <a className="carousel-control-prev" role="button" type="button" onClick={prev}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" role="button" type="button" onClick={next}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>

            </div >}
            {isModalOpen &&
                <>
                    <div className='backdrop'>
                        <button
                            type="button"
                            className="close close-prewiew"
                            onClick={closeModal}
                        >&times;</button>
                        <div>
                            <div id=""
                                className=""
                                data-ride="carousel"
                                onTouchStart={e => handleTouchStart(e)}
                                onTouchMove={e => handleTouchMove(e)}>
                                <div className="">
                                    {images.map((image, index) => (
                                        <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
                                            <div className='imageWrapper'
                                                onClick={handleBackgroundClick}>
                                                <img src={image} className="d-block" alt={`slide ${index}`} onClick={openModal} />
                                                <div className="carousel-caption prewiew-caption">
                                                    <h5 className='text-light' style={{ fontWeight: '700' }}>{`Slika ${index + 1}/${images.length}`}</h5>
                                                    <a className="carousel-control-prev" role="button" type="button" onClick={prev}>
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="sr-only">Previous</span>
                                                    </a>
                                                    <a className="carousel-control-next" role="button" type="button" onClick={next}>
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="sr-only">Next</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div >
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default ListingCarousel;
