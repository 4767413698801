import { useDispatch, useSelector } from "react-redux";
import { State, actionsCreators } from "../../state";
import { bindActionCreators } from "redux";
import { login } from "../../services/userService";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const LoginModal = () => {

    const dispatch = useDispatch();
    const { isVisibleModal, changeUserState, loadApartments, changeListApartments, changeIsLoading } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.modal);
    const userState = useSelector((state: State) => state.user);
    const filterState = useSelector((state: State) => state.filter);
    const apartments = useSelector((state: State) => state.apartments);
    const loading = useSelector((state: State) => state.loading);
    const [message, setMessage] = useState("");
    const path = useLocation().pathname;
    const loginUser = () =>{
        if(userState.state.logged.email === ""){
            setMessage("Unesite e-mail adresu.")
            return;
        }
        if(userState.state.logged.password === ""){
            setMessage("Unesite lozinku.")
            return;
        }

        login(userState).then(res => {
            localStorage.setItem('loggedUser',JSON.stringify({
                email: userState.state.logged.email,
                roles: getRoles(res.data),
                token: res.data,
                expires: getExpires(res.data)
            }));
            modal.state.isVisibleLoginModal = false;
            isVisibleModal({state : modal.state});
            loading.isLoading = true;
            changeIsLoading(loading);
            apartments.items=[];
            apartments.totalCount = -1;
            changeListApartments(apartments);
            loadApartments(filterState, path);
            // const rezultati = document.getElementById('advanced_submit_4');
            // rezultati?.click();
        }).catch(error => {
            setMessage(error.response.data);
        });
    }

    const getRoles = (token:string) => {
        let jwtData = token.split('.')[1];
        let decodedJwtJsonData = window.atob(jwtData);
        let decodedJwtData = JSON.parse(decodedJwtJsonData);
        return [decodedJwtData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]];
    }

    const getExpires = (token:string) => {
        let jwtData = token.split('.')[1];
        let decodedJwtJsonData = window.atob(jwtData);
        let decodedJwtData = JSON.parse(decodedJwtJsonData);
        return [decodedJwtData["exp"]];
    }

    return(
        <div className="modal-content mt-5">
            <div className="modal-header">
                <h4 className="modal-title">Prijava</h4>
                <button 
                    type="button" 
                    className="close" 
                    data-dismiss="modal"
                    onClick={() => {
                        modal.state.isVisibleLoginModal = false;
                        isVisibleModal({state : modal.state});
                    }}
                    >&times;</button>
            </div>
            <div className="modal-body">
                <div>
                    <input 
                        type="email" 
                        className="form-control" 
                        name="username" 
                        placeholder={"E-mail adresa"} 
                        onChange={(event) => {
                            userState.state.logged.email = event.target.value;
                            changeUserState({state:userState.state});
                        }}
                        value = {userState.state.logged.email}/>
                </div>
                <div className="mt-2">
                    <input 
                        type="password" 
                        className="form-control" 
                        name="password" 
                        placeholder={"Lozinka"} 
                        onChange={(event) => {
                            userState.state.logged.password = event.target.value;
                            changeUserState({state:userState.state});
                        }}
                        value = {userState.state.logged.password}/>
                </div>
                {message !== "" && <p className="text-danger">{message}</p>}
                <div style={{float:"right"}}>
                    <button 
                        style={{marginTop:"5px",backgroundColor:"var(--primary-color)"}} 
                        className="btn btn-primary btn-sm"
                        role="button"
                        onClick={() =>{
                            modal.state.isVisibleLoginModal = false;
                            modal.state.isVisbleRegisterModal = true;
                            isVisibleModal({state:modal.state});
                        }}
                        >
                        <span aria-hidden="true">Registracija</span>
                    </button>
                    <button 
                        onClick={() => {
                            loginUser();
                        }}
                        type="button" 
                        style={{width:"75px", marginLeft:"1px", marginTop:"5px"}} 
                        className="btn btn-success btn-sm" >
                                Prijavi se
                    </button>
                </div>
            </div>
        </div>
    )
}