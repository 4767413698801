import axios from "axios";
import { UserState } from "../state/reducers/userReducer";


export const login = (user: UserState) => {
    var conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Users/login`,
        method: "POST",
        data: user.state.logged
    };
    return axios.request(conf);
}

export const register = (user: UserState) => {
    var conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Users/register`,
        method: "POST",
        data: user.state.register
    };
    return axios.request(conf);
}