import { useDispatch, useSelector } from "react-redux";
import { State, actionsCreators } from "../../state";
import { bindActionCreators } from "redux";
import { register } from "../../services/userService";
import { useState } from "react";

export const RegisterModal = () => {

    const dispatch = useDispatch();
    const { isVisibleModal, changeUserState } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.modal);
    const userState = useSelector((state: State) => state.user);
    const [pass, setPass] = useState("");
    const [message, setMessage] = useState("");
    
    const registerUser = () =>{
        if(userState.state.register.email === ""){
            setMessage("Unesite e-mail adresu.");
            return;
        }
        if(userState.state.register.password === ""){
            setMessage("Unesite lozinku.");
            return;
        }
        if(userState.state.register.password !== pass){
            setMessage("Lozinke se ne podudaraju.")
            return;
        }else{
            setMessage("")
        }

        register(userState).then(() => {
            modal.state.isVisbleRegisterModal = false;
            modal.state.alert.messages = ["Uspešno ste se registrovali."];
            modal.state.alert.isVisibleAlert = true;
            userState.state.register.email = "";
            userState.state.register.password = "";
            isVisibleModal({state : modal.state});
            changeUserState({state:userState.state});
        }).catch(error => {
            setMessage(error.response.data);
        });
    }

    return(
        <div className="modal-content mt-5">
            <div className="modal-header">
                <h4 className="modal-title">Registacija</h4>
                <button 
                    type="button" 
                    className="close" 
                    data-dismiss="modal"
                    onClick={() => {
                        modal.state.isVisbleRegisterModal = false;
                        isVisibleModal({state : modal.state});
                    }}
                    >&times;</button>
            </div>
            <div className="modal-body">
                <div>
                    <input 
                        type="email" 
                        className="form-control" 
                        name="username" 
                        placeholder={"E-mail adresa"} 
                        onChange={(event) => {
                            userState.state.register.email = event.target.value;
                            changeUserState({state:userState.state});
                        }}
                        value = {userState.state.register.email}/>
                </div>
                <div className="mt-2">
                    <input 
                        type="password" 
                        className="form-control" 
                        name="password" 
                        placeholder={"Lozinka"} 
                        onChange={(event) => {
                            userState.state.register.password = event.target.value;
                            changeUserState({state:userState.state});
                        }}
                        value = {userState.state.register.password}/>
                </div>
                <div className="mt-2">
                    <input 
                        type="password" 
                        className="form-control" 
                        name="password" 
                        placeholder={"Ponovite lozinku"} 
                        onChange={(event) => {
                            setPass(event.target.value)
                        }}
                        value = {pass}/>
                </div>
                <p  className="text-danger">{message !== "" && message}</p>
                <div style={{float:"right"}}>
                    <button 
                        onClick={() => {
                            registerUser();
                        }}
                        type="button" 
                        style={{width:"120px", marginLeft:"1px", marginTop:"5px"}} 
                        className="btn btn-success btn-sm" >
                                Registuruj se
                    </button>
                </div>
            </div>
        </div>
    )
}