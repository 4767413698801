import { useDispatch, useSelector } from "react-redux";
import { State, actionsCreators } from "../../state";
import { bindActionCreators } from "redux";
import { login } from "../../services/userService";

export const Alert = () => {

    const dispatch = useDispatch();
    const { isVisibleModal, changeUserState } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.modal);

    const messages = modal.state.alert.messages.map((m, index) => <p key={index}>{m}</p>)
    return(
        <div className="modal-content mt-5">
            <div className="modal-header">
                <h4 className="modal-title">Obaveštenje</h4>
                <button 
                    type="button" 
                    className="close" 
                    data-dismiss="modal"
                    onClick={() => {
                        modal.state.alert.isVisibleAlert = false;
                        isVisibleModal({state : modal.state});
                    }}
                    >&times;</button>
            </div>
            <div className="modal-body">
                {messages}
                <div style={{float:"right"}}>
                    <button 
                        onClick={() => {
                            modal.state.alert.isVisibleAlert = false;
                            isVisibleModal({state:modal.state})
                        }}
                        type="button" 
                        style={{width:"75px", marginLeft:"1px", marginTop:"5px"}} 
                        className="btn btn-success btn-sm" >
                                Ok
                    </button>
                </div>
            </div>
        </div>
    )
}