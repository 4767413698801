import Logo from "../../img/logo_light.png";

export const About = () => {
    return (
        <div className="row">
            <div className="col-lg-4 col-md-6">
                <img src={Logo} className="footer_logo" />
                <p>Nadji.rs je pretraživač nekretnina za teritoriju Srbije. Kroz elegantan dizajn vam daje mogućnost pregleda preko 300.000 nekretnina objavljenih na različitim sajtovima za oglašavanje.</p>
            </div>
            <div className="col-lg-2 col-md-6">
                <h4 className="footer_title">Kompanija</h4>
                <ul className="footer_list">
                    <li><a>Pravila korišćenja</a></li>
                    <li><a>Politika privatnosti</a></li>
                    <li><a>Poslovi</a></li>
                </ul>
            </div>
            <div className="col-lg-2 col-md-6">
                <h4 className="footer_title">O Nama</h4>
                <ul className="footer_list">
                    <li><a>O nama</a></li>
                    <li><a>Kontakt</a></li>
                    <li><a>Blog</a></li>
                </ul>
            </div>
            <div className="col-lg-4 col-md-6">
                <h4 className="footer_title">Adresa</h4>
                <ul className="footer_list">
                    <li><i className="fa fa-map-marker ml-0 mr-1"></i> Kapetana Vojislava Mrkote 127, Novi Sad, <br />Srbija</li>
                    <li><i className="fa fa-phone ml-0 mr-1"></i> +381 63 4343 9812</li>
                    <li><i className="fa fa-envelope ml-0 mr-1"></i>Info@nadji.rs</li>
                </ul>
            </div>
        </div>
        )
}