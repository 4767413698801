import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { filter } from "../Filter/Content";


export const SelectOfContent = () => {
    const dispatch = useDispatch();

    const { changeFilterParams, loadApartments, changeIsLoading, changeListApartments } = bindActionCreators(actionsCreators, dispatch);
    const filterState = useSelector((state: State) => state.filter);
    const apartments = useSelector((state: State) => state.apartments);
    const loading = useSelector((state: State) => state.loading);
    const [state, setState] = useState(filterState);
    const path = useLocation().pathname;
    const navigate = useNavigate();

    return (
        <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="input-group" id="sortBy2">
                    <select 
                        id="sortiranje" 
                        className="form-control" 
                        name="sortiranje"
                        onChange={(event) => {
                            event.preventDefault();
                            filterState.stateOfFilter["sortiranje"] = event.target.value;
                            loading.isLoading = true;
                            changeIsLoading(loading);
                            apartments.items=[];
                            changeListApartments(apartments);
                            loadApartments(filterState, path)
                            changeFilterParams(filterState);
                            navigate(`/?${filter(state)}`);
                            
                        }}
                        value={state.stateOfFilter["sortiranje"]}>
                            <option value="DatumO">Sortiranje po : Datumu (prvo najnoviji)</option>
                            <option value="DatumR">Sortiranje po : Datumu (prvo najstariji)</option>
                            <option value="CenaO">Sortiranje po : Ceni (prvo najskuplji)</option>
                            <option value="CenaR">Sortiranje po : Ceni (prvo najjeftiniji)</option>
                            <option value="KvadraturaO">Sortiranje po : Kvadraturi (prvo najveći)</option>
                            <option value="KvadraturaR">Sortiranje po : Kvadraturi (prvo najmanji)</option>
                    </select>
            </div>
        </div>
        )
}