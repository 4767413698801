import { ContentOfFilter } from "./Content"
import { HeaderOfFilter } from "./Header"

export const Filter = () => {
    return (
        <div className="container-fluid bg_img">
            <div className="container">
                <HeaderOfFilter />
                <ContentOfFilter />
            </div>
        </div>
        )
}