import { TouchEvent, useState } from "react";
import { Item } from "../../../state/reducers/apartmentReducer"
import imgPlaceholder from "../../../img/image.png";
import { AiTwotoneDelete, AiTwotoneHeart } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { State, actionsCreators } from "../../../state";
import { ListingMark } from "../../../state/reducers/apartmentReducer";
import { markListing } from "../../../services/listingService";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { Picture } from "./Picture";

export interface Props {
    item: Item,
    counter: number,
    setDipslayedPictures: (value: string[]) => void;
};

export const Pictures: React.FC<Props> = ({ item, counter, setDipslayedPictures }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isVisibleListingModal } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.listingModal);
    const userState = useSelector((state: State) => state.user);

    const [current, setCurrent] = useState(0);
    
    const pictures = item.pictures != null && item.pictures !== "" ? item.pictures.split(",").map(p => p.trim()).filter(p => p !== "") : [];

    const [flag, setFlag] = useState("active");
    const [flag2, setFlag2] = useState("");
    const [operation, setOperation] = useState("");
    const changeIndex = (o: string) => {
        let right = true;
        switch (o) {
            case "prev":
                right = false;
                break;
            default:
                break;
        }
        setFlag2(`active carousel-item-${!right ? "right":"left"}`);
        
        setTimeout(() => {
            setFlag("active");
        }, 20);

        setTimeout(() => {
            setFlag2("");
        }, 500);
        
        setFlag(`carousel-item-${right ? "next":"prev"} carousel-item-${right ? "right":"left"}`);
        var index = 0;
        switch (o) {
            case "next":
                index = current < pictures.length - 1 ? current + 1 : 0;
                break;
            case "prev":
                index = current > 0 ? current - 1 : pictures.length - 1;
                break;
            default:
                break;
        }
        setOperation(o);
        setCurrent(index);
    }

    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchStart(e.targetTouches[0].clientX);
      };
    
    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart === null || touchEnd === null) return;
    
        const distance = touchStart - touchEnd;
    
        if (distance > 50) {
          changeIndex("next");
        } else if (distance < -50) {
          changeIndex("prev");
        }
    
        setTouchStart(null);
        setTouchEnd(null);
    };

    const hancldeClick = () => {
        modal.state.isVisibleListingModal = true;
        isVisibleListingModal({ state: modal.state, item: item });
    }

    const handleCameraClick = () =>{
        setDipslayedPictures(pictures)
    }
    
    const links = item.pictures != null && item.pictures !== "" ? pictures.map((pic, index) => {
        if (index === current && pic !== "") {
            return (
                <Picture key={index} index={index} 
                                carouselClass={flag} 
                                pic={pic} 
                                href={!item.url.includes("nadji.in.rs") ? item.url : undefined}
                                onClick={() => item.url.includes("nadji.in.rs") && hancldeClick()}
                                target={!item.url.includes("nadji.in.rs") ? "_blank":undefined}
                    />
            )
        } else if (pic !== "") {
            if((current - index === 1 && operation === "next") || 
                (index - current === 1 && operation === "prev") ||
                (pictures.length - (current + index + 1) === 0 && operation  === "next") ||
                (index === 0 && current + 1 === pictures.length && operation  === "prev")
                ){
                return (
                    <Picture key={index} index={index} 
                                    carouselClass={flag2}  
                                    href={!item.url.includes("nadji.in.rs") ? item.url:undefined}
                                    pic={pic} 
                                    onClick={() => item.url.includes("nadji.in.rs") && hancldeClick()}
                                    target={!item.url.includes("nadji.in.rs") ? "_blank":undefined}
                        />
                )
            }else{
                return(
                    <Picture key={index} index={index} 
                                        carouselClass={""}  
                                        href={!item.url.includes("nadji.in.rs") ? item.url:undefined}
                                        pic={pic} 
                                        onClick={() => item.url.includes("nadji.in.rs") && hancldeClick()}
                                        target={!item.url.includes("nadji.in.rs") ? "_blank":undefined}
                            />
                )
            }
        }
    }) : item.url.includes("nadji.in.rs") ? <a type="button"
        onClick={() => {
            modal.state.isVisibleListingModal = true;
            isVisibleListingModal({ state: modal.state, item: item });
        }}>
        <img className="d-block w-100" src={imgPlaceholder} />
    </a>
        :
        <a href={item.url} target="_blank">
            <img className="d-block w-100" src={imgPlaceholder} />
        </a>;

    const arrow = (dataSlide: string, contentSpan: string) =>
        item.pictures == null || item.pictures === "" || pictures.length < 2 ?
                <a
                    type="button"
                    onClick={() => changeIndex(dataSlide)}
                    className={`carousel-control-${dataSlide}`}
                    role="button"
                    data-slide={dataSlide}
                    hidden>
                    <span className={`carousel-control-${dataSlide}-icon`} aria-hidden="true"></span>
                    <span className="sr-only">{contentSpan}</span>
                </a>
            :
                <a
                    type="button"
                    onClick={() => changeIndex(dataSlide)}
                    className={`carousel-control-${dataSlide}`}
                    role="button"
                    data-slide={dataSlide}>
                    <span className={`carousel-control-${dataSlide}-icon`} aria-hidden="true"></span>
                    <span className="sr-only">{contentSpan}</span>
                </a>;

    const [favorite, setFavorite] = useState(item.favorite);
    const [undesirable, setUndesirable] = useState(item.undesirable);
    const handleFavorite = () => {
        const listingMark: ListingMark = {
            email: userState.state.logged.email,
            listingId: item.id,
            favorite: !favorite,
            undesirable: false
        };
        markListing(listingMark);
        setFavorite(!favorite);
        setUndesirable(false);
    }

    const handleUndesirable = () => {
        const listingMark: ListingMark = {
            email: userState.state.logged.email,
            listingId: item.id,
            favorite: false,
            undesirable: !undesirable
        };
        markListing(listingMark);
        setUndesirable(!undesirable);
        setFavorite(false);
    }

    return (
        <div className="row">
            <div id={`carouselExampleControls${counter}`}
                className="carousel slide "
                data-interval="false"
                data-ride="carousel"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="prop_new_details">
                    <div className="prop_new_details_back"></div>
                    <div className="property_media_up">
                        {userState.state.logged.email !== "" &&
                            <AiTwotoneHeart
                                size={24}
                                type="button"
                                className={`favorite${favorite ? "-active" : ""}`}
                                onClick={() => handleFavorite()}
                            />
                        }
                        {userState.state.logged.email === item.email &&
                            <MdEdit
                                size={24}
                                type="button"
                                className="edit"
                                onClick={() => navigate(`listing/${item.id}`)}
                            />
                        }
                        {userState.state.logged.email !== "" &&
                            <AiTwotoneDelete
                                size={24}
                                type="button"
                                className={`undesirable${undesirable ? "-active" : ""}`}
                                onClick={() => handleUndesirable()}
                            />
                        }
                    </div>
                    <button className="property_media" onClick={handleCameraClick}>
                        <i className="fa fa-camera" aria-hidden="true"></i> {item.pictures == null && item.pictures === "" ? 0 : pictures.length}
                    </button>
                    <div className="property_location_image">
                        <span className="property_marker"></span>{item.cityPart == null ? "" : item.cityPart + ", "} {item.city}
                    </div>
                </div>
                <div className="carousel-inner">
                    {links}
                </div>
                {arrow("prev", "Previous")}
                {arrow("next", "Next")}
            </div>
        </div>
    )
}