import { useSelector } from "react-redux";
import { State } from "../../state";
import { SelectOfContent } from "./Select"

export const HeaderOfContent = () => {

    const pageNumber = useSelector((state: State) => state.filter.stateOfFilter.pageNumber);
    const data = useSelector((state: State) => state.apartments.items);
    const totalCount = useSelector((state: State) => state.apartments.totalCount);

    const start = (Number(pageNumber) - 1) * 18 + 1;
    const end = start + data.length - 1;
    
    const pages = () => {
        if(totalCount == 1){
            return totalCount + " rezultat"
        }else if(totalCount<=18){
            return totalCount + " rezultata"
        }else{
            return start.toLocaleString() + " - " + end.toLocaleString() + " od ukupno " + totalCount.toLocaleString() + " rezultata"
        }
    }

    const currentPage:string = pages();

    return (
        <div className="row align-items-center mt-5 mb-4" id="resultsBar">
            <div className="col-lg-8 col-md-7 col-sm-12">
                <h4 id="currentPage">{totalCount > -1 && currentPage}</h4>
            </div>
            {totalCount > 0 && <SelectOfContent />}
        </div>
        )
}