import { useDispatch, useSelector } from "react-redux";
import { Item } from "../../../state/reducers/apartmentReducer";
import { bindActionCreators } from "redux";
import { State, actionsCreators } from "../../../state";
import image from "../../../img/image.png";

interface Props {
    index: number,
    carouselClass:string,
    pic:string,
    target:string|undefined,
    onClick:Function|undefined,
    href:string|undefined
};

export const Picture = ({ index, carouselClass, pic, target, href, onClick }: Props) =>{
    
    return(
        <div key={index} className={`carousel-item ${carouselClass}`}>
            <a type="button"
                href={href && href}
                target={target}
                onClick={() => onClick && onClick()} 
                className="d-flex justify-content-center align-items-center">
                <img
                    className="d-block w-100"
                    onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = image }}
                    style={{ height: "280px" }}
                    src={pic} />
            </a>
        </div>
    )
}