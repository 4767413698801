import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { Option, ParamsOfSearch } from "../../state/reducers/filterReducer"

export interface Props {
    name: string,
    defaultOption: string,
    options: Option[]
}

export const SelectParamFilter: React.FC<Props> = ({ name, defaultOption, options }: Props) => {

    const dispatch = useDispatch();

    const { changeFilterParams } = bindActionCreators(actionsCreators, dispatch);

    const filterState = useSelector((state: State) => state.filter);

    const rowOption = options.map((option,index) => <option key={index} value={option.value}>{option.optionContent}</option>)

    return (
        <div className="form-group col-lg-2 col-md-6 col-6 red_design">
            <select 
                className="form-control" 
                name={name}
                onChange={(event) => {
                    filterState.stateOfFilter[name as keyof ParamsOfSearch] = event.target.value;
                    changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                }}
            >
                <option value="">{defaultOption}</option>
                {rowOption}l
            </select>
        </div>
        )
}