import { ActionType } from "../action-types";
import { Action } from "../actions";
import { Item } from "./apartmentReducer";

export interface ModalState {
    state: State
}

export interface ListingModalState {
    state: State,
    item: Item | null
}

export interface Alert {
    isVisibleAlert: boolean,
    messages: string[]
}

export interface State {
    isVisibleGuideModal: boolean,
    isVisibleLoginModal: boolean,
    isVisbleRegisterModal: boolean,
    isVisibleListingModal: boolean
    alert: Alert
}

const initialState: ModalState = {
    state: {
        isVisibleGuideModal: false,
        isVisibleLoginModal: false,
        isVisbleRegisterModal: false,
        isVisibleListingModal: false,
        alert: {
            isVisibleAlert: false,
            messages: []
        }
    }
}

const initialListingState: ListingModalState = {
    state: {
        isVisibleGuideModal: false,
        isVisibleLoginModal: false,
        isVisbleRegisterModal: false,
        isVisibleListingModal: false,
        alert: {
            isVisibleAlert: false,
            messages: []
        }
    },
    item: null
}

const modalReducer = (state: ModalState = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.IS_VISIBLE_MODAL:
            return action.payload;

        default:
            return state;
    }
}

export const listingModalReducer = (state: ListingModalState = initialListingState, action: Action) => {
    switch (action.type) {
        case ActionType.IS_VISIBLE_L_MODAL:
            return action.payload;
        default:
            return state;
    }
}



export default modalReducer;