import axios from "axios";


export const uploadPictures = async (files: Array<File>) => {
  const token = localStorage.getItem('loggedUser') !== null ? JSON.parse(localStorage.getItem('loggedUser')!).token : null;
  const formData = new FormData();
  files.forEach((file: File) => {
    formData.append("imageFiles", file, file.name)
  })
  var conf = {
    baseURL: `${process.env.REACT_APP_API_URL}Pictures/upload`,
    method: "POST",
    headers: { 'Authorization': "Bearer " + token },
    data: formData
  };
  return await axios.request(conf);
}

export const getInteretImg = async (url:string) => {
  var conf = {
    baseURL: url,
    method: "GET",
    type: 'blob'
  };
  return await axios.request(conf);
}