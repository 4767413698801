import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { Option, ParamsOfSearch, StateOfFilter } from "../../state/reducers/filterReducer";
import { AutoCompleteField, MultiSelectOption } from "./AutoCompleteField";
import { InputParamFilter } from "./InputParam";
import { SelectOfFilter } from "./Select";
import { SelectParamFilter } from "./SelectParam";
import { getLocation } from '../../services/locationService';
import { Location } from "../../state/reducers/locationReducer";

export const ContentOfFilter = () => {
    const tipovi: Option[] = [
        {
            value: "Stan",
            optionContent: "Stan"
        },
        {
            value: "Kuca",
            optionContent: "Kuća"
        },
        {
            value: "PoslovniProstor",
            optionContent: "Poslovni prostor"
        },
        {
            value: "Zemljiste",
            optionContent: "Zemljište"
        },
        {
            value: "Garaza",
            optionContent: "Garaže"
        },
    ];

    const sobe: Option[] = [
        {
            value: "0.5",
            optionContent: "0.5"
        },
        {
            value: "1.0",
            optionContent: "1.0"
        },
        {
            value: "1.5",
            optionContent: "1.5"
        },
        {
            value: "2.0",
            optionContent: "2.0"
        },
        {
            value: "2.5",
            optionContent: "2.5"
        },
        {
            value: "3.0",
            optionContent: "3.0"
        },
        {
            value: "3.5",
            optionContent: "3.5"
        },
        {
            value: "4.0",
            optionContent: "4.0"
        },
        {
            value: "4.5",
            optionContent: "4.5"
        },
        {
            value: "5.0+",
            optionContent: "5.0+"
        },
    ];


    const dispatch = useDispatch();
    const { loadApartments, loadCities, changeLocations, changeIsLoading, changeListApartments, loadCityParts,
        changeCityState, changeCityPartState } = bindActionCreators(actionsCreators, dispatch);

    const filterState = useSelector((state: State) => state.filter);
    const cityState = useSelector((state: State) => state.cities);
    const locationState = useSelector((state: State) => state.locations);
    const loading = useSelector((state: State) => state.loading);
    const apartments = useSelector((state: State) => state.apartments);
    const cityPartsState = useSelector((state: State) => state.cityParts);
    const path = useLocation().pathname;
    const gradovi2: Option[] = cityState.items !== undefined ? cityState.items.map(city => {
        return { value: city.cityName, optionContent: city.cityName.trim() }
    }) : [];

    const krajevi: MultiSelectOption[] = cityPartsState.items !== undefined ? cityPartsState.items.map(cityPart => {
        return { id: cityPart.originalCityPartName, name: cityPart.originalCityPartName }
    }) : [];

    const gradovi: MultiSelectOption[] = cityState.items !== undefined ? cityState.items.map(city => {
        return { id: city.cityName, name: city.cityName }
    }) : [];

    const [locations, setLocations] = useState<MultiSelectOption[]>([]);
    const [showRooms, setShowRooms] = useState<boolean>(true);
    const [showPlotArea, setShowPlotArea] = useState<boolean>(true);

    // const locations: MultiSelectOption[] = locationState.items !== undefined ? locationState.items.map(location => {
    //     let displayValue = `${location.name}, ${location.city != null ? `${location.city},`:""} ${location.state}, ${location.country}`;
    //     return { id: location.id.toString(), name: displayValue }
    // }) : [];
    
    const navigate = useNavigate();

    const [pageNum, setPageNum] = useState(filterState.stateOfFilter.pageNumber);

    const changeCities = (p: string) => {
        loadCities(p);
    }

    const loadLocations = (p: string) => {
        if(!locationState.loading){
            // console.log("P: " + p)
            const newLocationState = { ...locationState, loading: true };
            changeLocations(newLocationState);
            // locationState.loading = true;
            // changeLocations(locationState);
            // changeLocations({loading: true, items:locationState.items})
            getLocation(p).then(r => {
                const updatedLocationState = { loading: false, items: r.data };
                changeLocations(updatedLocationState);
                const locationRes:Location[] = r.data;
                const locationsSelect: MultiSelectOption[] = locationRes !== undefined ? locationRes.reduce((acc: MultiSelectOption[], location: Location) => {
                    let displayValue = "---------------------------";
                
                    if (location.city != null && location.city !== location.name) {
                        displayValue = `${location.name}, ${location.city}`;
                    } else if (location.quarter != null && location.quarter !== location.name) {
                        displayValue = `${location.name}, ${location.quarter}`;
                    } else if (location.county != null && location.county !== location.name) {
                        displayValue = `${location.name}, ${location.county}`;
                    } else if (location.state != null && location.state !== location.name) {
                        displayValue = `${location.name}, ${location.state}`;
                    } else if (location.country != null && location.country !== location.name) {
                        displayValue = `${location.name}, ${location.country}`;
                    }
                
                    // Proveravamo da li već postoji element sa istim name u acc nizu
                    const existingLocation = acc.find(item => item.name === displayValue);
                    if (!existingLocation) {
                        acc.push({ id: location.id.toString(), name: displayValue });
                    }
                
                    return acc;
                }, []) : [];
                setLocations(locationsSelect);

                // const locationsIds = p.split(",").map(item => {
                //     const num = parseFloat(item.trim());
                //     return isNaN(num) ? null : num;
                // }).filter(item => item !== null);

                // console.log(locationsIds.length)
                // console.log(r.data.length)
                // if(r.data.length == locationsIds.length){
                //     console.log("Ponovo ucitavam")
                //     getLocation("").then(r => {
                //         const updatedLocationState = { loading: false, items: r.data };
                //         changeLocations(updatedLocationState);
                //         const locationRes:Location[] = r.data;
                //         const locationsSelect: MultiSelectOption[] = locationRes !== undefined ? locationRes.map(location => {
                //             let displayValue = `${location.name}, ${location.city != null ? `${location.city},`:""} ${location.state}, ${location.country}`;
                //             return { id: location.id.toString(), name: displayValue }
                //         }) : [];
                //         setLocations(locationsSelect);
                //     })
                // }
            })
        }
    }
    
    let typingTimer: ReturnType<typeof setTimeout>;
    const handleChnageLocation = (p: string) =>{
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function() {
            if(p.length > 2){
                loadLocations(p);
            }
        }, 500);
    }

    const changeCityParts = (p: string) => {
        loadCityParts(filterState.stateOfFilter.grad, p);
    }

    const [params] = useSearchParams();
    const page = params.get("page");
    const locations_param = params.get("location");

    // if (page !== null && page !== pageNum) {
    //     filterState.stateOfFilter.pageNumber = page!;
    //     apartments.items = [];
    //     loadApartments(filterState, path);
    //     setPageNum(page!);
    // }

    useEffect(() => {
        setPageNum(filterState.stateOfFilter.pageNumber);

        const roomsFilter = ['Zemljiste', 'Garaza'].filter(t => t === filterState.stateOfFilter.tip).length === 0;
        setShowRooms(roomsFilter);
        filterState.stateOfFilter.min_soba = roomsFilter ? filterState.stateOfFilter.min_soba : "";
        filterState.stateOfFilter.max_soba = roomsFilter ? filterState.stateOfFilter.max_soba : "";

        const plotFilter = filterState.stateOfFilter.tip === 'Kuca'
        setShowPlotArea(plotFilter);
        filterState.stateOfFilter.min_povrsina_placa = plotFilter ? filterState.stateOfFilter.min_povrsina_placa : "";
        filterState.stateOfFilter.max_povrsina_placa = plotFilter ? filterState.stateOfFilter.max_povrsina_placa : "";

        loadLocations(locations_param || "");
        loadCities("");
    }, [filterState.stateOfFilter.tip]);
    return (
        <div className="row show-when-loaded">
            <div className="col-12">
                <form id="searchForm" className="searchform_nadji">
                    {/* Locations: {JSON.stringify(locationState)} */}
                    <div className="form-row">
                        <SelectOfFilter name="tip" options={tipovi} />
                        {/* <SelectCity name="grad" options={gradovi2} /> */}
                        <div className={`form-group col-lg-${showRooms ? "6":"9"} col-md-12 col-sm-12`}>

                            <AutoCompleteField
                                name="location"
                                options={locations}
                                changeSearch={handleChnageLocation}
                                placeholder={"Unesite lokaciju..."}
                                hasSelectAll={true}
                                loading={locationState.loading}
                            />
                        </div>
                        <InputParamFilter className="form-group col-lg-3 col-md-6 col-6 red_design" name="cena_po_kvadratu_do" placeholder="Cena po m² do" />
                        {/* <div className="form-group col-lg-4 col-md-6 col-sm-12">

                            <AutoCompleteField
                                name="grad"
                                options={gradovi}
                                changeSearch={changeCities}
                                placeholder={"Grad"}
                                hasSelectAll={true}
                            />
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">

                            <AutoCompleteField
                                name="krajevi"
                                options={krajevi}
                                changeSearch={changeCityParts}
                                placeholder={"Delovi grada"}
                                hasSelectAll={true}
                            />
                        </div> */}
                        <div className="clearfix mb-4"></div>
                        <InputParamFilter className="form-group col-lg-2 col-md-6 col-6 red_design" name="min_kvadratura" placeholder="Kvadratura od" />
                        <InputParamFilter className={`form-group col-lg-2 col-md-6 col-6 red_design ${!showRooms ? "ml-for-special-type" : ""}`} name="max_kvadratura" placeholder="Kvadratura do" />
                        <InputParamFilter className={`form-group col-lg-2 col-md-6 col-6 red_design ${!showRooms ? "ml-for-special-type" : ""}`} name="min_cena" placeholder="Cena od" />
                        <InputParamFilter className={`form-group col-lg-2 col-md-6 col-6 red_design ${!showRooms ? "ml-for-special-type" : ""}`} name="max_cena" placeholder="Cena do" />
                        {showRooms && <SelectParamFilter name="min_soba" defaultOption="Soba od" options={sobe} />}
                        {showRooms && <SelectParamFilter name="max_soba" defaultOption="Soba do" options={sobe} />}
                        {showPlotArea && <InputParamFilter className={`form-group col-lg-3 col-md-6 col-6 red_design`} name="min_povrsina_placa" placeholder="Površina placa od" />}
                        {showPlotArea && <InputParamFilter className={`form-group col-lg-3 col-md-6 col-6 red_design`} name="max_povrsina_placa" placeholder="Površina placa do" />}
                        <ScrollIntoView
                            selector="#rezultati"
                        >
                            <button
                                type="button"
                                className="wpresidence_button"
                                id="advanced_submit_4"
                                onClick={() => {
                                    filterState.stateOfFilter.pageNumber = "1";
                                    filterState.stateOfFilter.duplicate_group = "";
                                    navigate(`${path}?${filter(filterState)}`);
                                    loading.isLoading = true;
                                    changeIsLoading(loading);
                                    apartments.items = [];
                                    apartments.totalCount = -1;
                                    changeListApartments(apartments);
                                    loadApartments(filterState, path);
                                }}
                            >
                                <i className="fa fa-search ml-0 mr-1"></i>
                                Pretraga
                            </button>
                        </ScrollIntoView>
                    </div>
                </form>
            </div>
        </div>
    )
}

export const filter = (state: StateOfFilter) => {
    return Object.keys(state.stateOfFilter).map((key, index) => {
        const value = state.stateOfFilter[key as keyof ParamsOfSearch];
        if (key === "pageNumber") {
            return (value !== "") && `pageNumber=${value}`;
        }
        // if(key==="duplicate_group"){
        //     return;
        // }
        return (value !== "") && `${key}=${value}`;
    }).filter(param => {
        if (param) {
            return param;
        }
    }).join("&");
}