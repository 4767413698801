import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State, actionsCreators } from "../../state";
import { Logo } from "./Logo"
import { NavLink, useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";

export const Nav = () => {

    const dispatch = useDispatch();
    const { isVisibleModal, changeUserState, loadApartments, changeListApartments, changeIsLoading } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.modal);
    const apartments = useSelector((state: State) => state.apartments);
    const user = useSelector((state: State) => state.user);
    const filterState = useSelector((state: State) => state.filter);
    const loading = useSelector((state: State) => state.loading);
    const email = JSON.parse(localStorage.getItem('loggedUser')!) !== null ? JSON.parse(localStorage.getItem('loggedUser')!).email : null;
    const path = useLocation().pathname;
    const menu = email !== null && user.state.logged.email !== "" &&
        <NavDropdown title="Pretraga oglasa (tip)" id="basic-nav-dropdown" className="active" >
            <NavDropdown.Item href="/">Svi oglasi</NavDropdown.Item>
            <NavDropdown.Item href={`/favoriti?sortiranje=DatumO&pageNumber=1${filterState.stateOfFilter.showFilter ? "&showFilter=true":""}`}>Favoriti</NavDropdown.Item>
            <NavDropdown.Item href={`/obrisani?sortiranje=DatumO&pageNumber=1${filterState.stateOfFilter.showFilter ? "&showFilter=true":""}`}>Obrisani</NavDropdown.Item>
        </NavDropdown>;
    const addListing = email != null && user.state.logged.email !== "" &&
        <li className="nav-item active">
            <NavLink className="nav-link" to={"/listing"}>Dodaj oglas</NavLink>
        </li>

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (

        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar_nadji">
            <Logo />
            <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse menu_nadji`} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto user">
                    {addListing}
                    {menu}
                    <li className="nav-item active">
                        <a
                            className="nav-link"
                            data-toggle="modal"
                            href="#"
                            data-target="#myModal"
                            onClick={() => {
                                modal.state.isVisibleGuideModal = true;
                                modal.state.isVisibleLoginModal = false;
                                isVisibleModal({ state: modal.state });
                            }}
                        >Uputstvo</a>
                    </li>
                    {email !== null && user.state.logged.email !== "" ?
                        <li id="loggedIn" >
                            <a className="nav-link" href="#"><b id="login">{email}</b></a>
                            <div className="invisible1"></div>
                            <div className="user-menu">
                                <ul style={{ padding: '0px' }}>
                                    <li style={{ padding: '0px' }}>
                                        <a href="/" className="link" onClick={() => {
                                            localStorage.clear();
                                            user.state.logged.email = "";
                                            user.state.logged.password = "";
                                            changeUserState({ state: user.state });
                                            loading.isLoading = true;
                                            changeIsLoading(loading);
                                            apartments.items = [];
                                            apartments.totalCount = -1;
                                            changeListApartments(apartments);
                                            loadApartments(filterState, path);
                                        }}><b>Odjavi se</b></a>
                                    </li>
                                </ul>
                            </div>
                        </li> :
                        <li className="nav-item active">
                            <a
                                className="nav-link"
                                data-toggle="modal"
                                href="#"
                                onClick={() => {
                                    modal.state.isVisibleGuideModal = false;
                                    modal.state.isVisibleLoginModal = true;
                                    isVisibleModal({ state: modal.state });
                                }}
                            >Prijavi se</a>
                        </li>
                    }
                </ul>
            </div>
        </nav>
    )
}