import axios from "axios"
import { Dispatch } from "react"
import { ActionType } from "../action-types/index"
import { Action } from "../actions"
import { Items } from "../reducers/apartmentReducer"
import { CityParts } from "../reducers/cityPartReducer"
import { Cities } from "../reducers/cityReducer"
import { ParamsOfSearch, StateOfFilter } from "../reducers/filterReducer"
import { Loading } from "../reducers/loadingReducer"
import { ListingModalState, ModalState } from "../reducers/modalReducer"
import { UserState } from "../reducers/userReducer"
import { Locations } from "../reducers/locationReducer"



export const loadApartments = (stateOfFilter: StateOfFilter, u: string) => {
    const token = localStorage.getItem('loggedUser') !== null ? JSON.parse(localStorage.getItem('loggedUser')!).token : null;
    const filter = Object.keys(stateOfFilter.stateOfFilter).map((key) => {
        const value = stateOfFilter.stateOfFilter[key as keyof ParamsOfSearch];
        return (value !== "") && `${key}=${value}`;
    }).filter(param => {
        if (param) {
            return param;
        }
    }).join("&");
    const conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Listings${u}?${filter}&pageSize=18&_=1654008910666`,
        headers: { 'Authorization': "Bearer " + token },
        method: "GET"
    }
    return async (dispatch: Dispatch<Action>) => {
        return await axios.request(conf)
            .then((response) => {
                let p = {
                    items: response.data.items,
                    totalCount: response.data.totalCount,
                    isLoading: false
                }
                dispatch({
                    type: ActionType.LOAD_APARTMENTS,
                    payload: p
                });
                dispatch({
                    type: ActionType.IS_LOADING,
                    payload: { isLoading: false }
                });
            })
    }
}

export const loadCities = (name: string) => {
    return async (dispatch: Dispatch<Action>) => {
        return await axios.get(`${process.env.REACT_APP_API_URL}Cities/byName?name=` + name)
            .then((response) => {
                dispatch({
                    type: ActionType.LOAD_CITIES,
                    payload: response.data
                });
            })
    }
}

export const getAllCities = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}Cities/byName?name=`)
        return response.data.items
    } catch (error) {
        throw new Error("There has been problem with getting cities,please try again later.")
    }
}

export const changeCityState = (state: Cities) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOAD_CITIES,
            payload: state
        })
    }
}

export const loadCityParts = (cityName: string, partName: string) => {
    return async (dispatch: Dispatch<Action>) => {
        return await axios.get(`${process.env.REACT_APP_API_URL}CityParts/byCityNameAndPartName?cityName=` + cityName + "&partName=" + partName)
            .then((response) => {
                dispatch({
                    type: ActionType.LOAD_CITY_PARTS,
                    payload: response.data
                });
            })
    }
}

export const getCityPartsByCity = async (cityName: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}CityParts/byCityNameAndPartName?cityName=` + cityName + "&partName=")
        return response.data.items
    } catch (error) {
        throw new Error("There has been problem with getting city parts,please try again later.")
    }
}

export const changeLocations = (state: Locations) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_LOCATIONS,
            payload: state
        })
    }
}

export const changeCityPartState = (state: CityParts) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOAD_CITY_PARTS,
            payload: state
        })
    }
}

export const changeFilterParams = (stateOfFilter: StateOfFilter) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_PARAMS,
            payload: stateOfFilter
        })
    }
}

export const isVisibleModal = (isVisible: ModalState) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.IS_VISIBLE_MODAL,
            payload: isVisible
        })
    }
}

export const isVisibleListingModal = (state: ListingModalState) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.IS_VISIBLE_L_MODAL,
            payload: state
        })
    }
}

export const changeUserState = (state: UserState) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHNAGE_USER_STATE,
            payload: state
        })
    }
}

export const changeVisibleDuplicates = (isVisible: boolean) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.IS_VISIBLE_DUPLICATES,
            payload: isVisible
        })
    }
}

export const changeIsLoading = (state: Loading) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.IS_LOADING,
            payload: state
        })
    }
}

export const changeListApartments = (state: Items) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_LIST_APARTMETS,
            payload: state
        })
    }
}