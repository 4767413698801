import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";

export const HeaderOfFilter = () => {

    const dispatch = useDispatch();

    const { changeFilterParams } = bindActionCreators(actionsCreators, dispatch);

    const filterState = useSelector((state: State) => state.filter);

    return (
        <div className="row search-row show-when-loaded search_nadji">
            <div className="col-12">
                <div className="tabs" id="tabs">
                    <div className="tab-div" style={{ left: "0" }} >
                        <a 
                            onClick={() =>{
                                filterState.stateOfFilter["akcija"] = "Prodaja";
                                changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                            }}
                            aria-controls="foreclosure" 
                            className={`tab-button ${filterState.stateOfFilter.akcija==="Prodaja" && "active"}`} 
                            >Prodaja</a>
                    </div>
                    <div className="tab-div" style={{ right: "0" }}>
                        <a 
                            onClick={() =>{
                                filterState.stateOfFilter["akcija"] = "Izdavanje";
                                changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                            }}
                            id="izdavanje" 
                            aria-controls="rentals" 
                            className={`tab-button ${filterState.stateOfFilter.akcija==="Izdavanje" && "active"}`}
                            >Izdavanje</a>
                    </div>
                </div>
            </div>
        </div>
        )
}