import { ActionType } from "../action-types/index";
import { Action } from "../actions/index";

export interface ListingMark {
    email: string,
    listingId: number,
    favorite: boolean,
    undesirable: boolean
}

const initialState: Items = {
    items: [],
    totalCount: -1,
    isLoading: false
};

export interface Items {
    items: Item[],
    totalCount: number,
    isLoading: boolean
};

enum ListingAction {
    Sale = 0,
    Rent
};

export enum ListingType {
    Apartment = 0,
    House,
    Commercial,
    Land,
    Garage
}



enum ListingWebsite {
    NekretnineRs,
    NsGroup,
    MojKvadrat,
    HaloOglasi,
    Oglasi,
    NadjiDom,
    CetiriZida
};

export interface Item {
    id: number,
    title: string,
    city: string,
    cityPart: string,
    price: number | null,
    area: number | null,
    rooms: number | null | string,
    description: string,
    pictures: string,
    created: Date | null,
    updated: Date | null,
    active: boolean | false,
    scraped: Date | false,
    url: string,
    date: Date | null,
    duplicateGroup: string | null,
    parsed: number | 0,
    action: string,
    type: string,
    website: string,
    activePictureIndex: number,
    duplicates: string,
    favorite: boolean | false,
    undesirable: boolean | false
    street: string | null,
    terrace: boolean,
    airCondition: boolean,
    elavator: boolean,
    floor: number | null,
    furnishingType: string,
    heatingType: string,
    email: string | null,
    plotArea: number | null
}

const apartmentReducer = (state: Items = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.LOAD_APARTMENTS:
            return action.payload;
        case ActionType.CHANGE_LIST_APARTMETS:
            return action.payload;
        default:
            return state;
    }
}

export default apartmentReducer;