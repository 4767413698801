import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: Loading = {
    isLoading:true
};

export interface Loading {
    isLoading:boolean
}

const loadingReducer = (state: Loading = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export default loadingReducer;