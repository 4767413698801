import { Content } from "./components/Content/Content";
import { Filter } from "./components/Filter/Filter";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { GuideModal } from "./components/Content/GuideModal";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "./state";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { LoginModal } from "./components/Content/LoginModal";
import { RegisterModal } from "./components/Content/RegisterModal";
import { Alert } from "./components/Content/Alert";
import { Favorite } from "./components/Content/Favorite";
import { Undesirable } from "./components/Content/Undesirable";
import AddListingSection from "./components/Add-Listing/AddListingSection";
import { All } from "./components/Content/All";
import ListingDetailsSection from "./components/Content/Listing/ListindDetailsModal/ListingDetailsSection";

ReactModal.setAppElement('#root');

function App() {


  const modal = useSelector((state: State) => state.modal);
  const listingModal = useSelector((state: State) => state.listingModal);

  const userState = useSelector((state: State) => state.user);

  const dispatch = useDispatch();
  const { isVisibleModal } = bindActionCreators(actionsCreators, dispatch);

  const setCookie = (cname: string, cvalue: boolean, exdays: number) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const getCookie = (cname: string) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const cookieValue = getCookie('returning_visitior');

  const privateRoute = (el: JSX.Element, p: string): React.ReactElement => {
    if (localStorage.getItem('loggedUser') === null) {
      return <Route path={p} element={<Navigate to="/" />} />
    }
    return <Route path={p} element={el} />
  }

  useEffect(() => {
    if (!cookieValue) {
      modal.state.isVisibleGuideModal = true;
      modal.state.isVisibleLoginModal = false;
      isVisibleModal(modal);
      setCookie('returning_visitior', true, 365);
    }
  }, []);

  return (
    <Router>
      <div className="main-container">
        <Header />
        <Routes>
          <Route path="/" element={<All />} />
          {privateRoute(<AddListingSection />, "/listing")}
          {privateRoute(<AddListingSection />, "/listing/:id")}
          {privateRoute(<Favorite />, "/favoriti")}
          {privateRoute(<Undesirable />, "/obrisani")}
        </Routes>
        <Footer />
        <ReactModal
          className={"modal-dialog"}
          isOpen={modal.state.isVisibleGuideModal}
          onRequestClose={() => {
            modal.state.isVisibleGuideModal = false;
            isVisibleModal({ state: modal.state });
          }}
          contentLabel="Example Modal"
          style={{ overlay: { zIndex: 1000 } }}
        >
          <GuideModal />
        </ReactModal>
        <ReactModal
          className={"modal-dialog"}
          isOpen={modal.state.isVisibleLoginModal}
          onRequestClose={() => {
            modal.state.isVisibleLoginModal = false;
            isVisibleModal({ state: modal.state });
          }}
          contentLabel="Example Modal"
          style={{ overlay: { zIndex: 1000 } }}
        >
          <LoginModal />
        </ReactModal>
        <ReactModal
          className={"modal-dialog"}
          isOpen={modal.state.isVisbleRegisterModal}
          onRequestClose={() => {
            modal.state.isVisbleRegisterModal = false;
            isVisibleModal({ state: modal.state });
          }}
          contentLabel="Example Modal"
          style={{ overlay: { zIndex: 1000 } }}
        >
          <RegisterModal />
        </ReactModal>
        <ReactModal
          className={"modal-dialog"}
          isOpen={modal.state.alert.isVisibleAlert}
          onRequestClose={() => {
            modal.state.alert.isVisibleAlert = false;
            isVisibleModal({ state: modal.state });
          }}
          contentLabel="Example Modal"
          style={{ overlay: { zIndex: 1000 } }}
        >
          <Alert />
        </ReactModal>
        <ReactModal
          className={"modal-dialog modal-xl"}
          isOpen={listingModal.state.isVisibleListingModal}
          onRequestClose={() => {
            listingModal.state.isVisibleListingModal = false;
            isVisibleModal({ state: modal.state });
          }}
          contentLabel="Example Modal"
          style={{ overlay: { zIndex: 1000 } }
          }
        // preventScroll={true}
        >
          <ListingDetailsSection />
        </ReactModal>
      </div>
    </Router>
  );
}

export default App;