import { About } from "./About";
import { SocialNetworks } from "./SocialNetworks";

export const Footer = () => {
    return (
        <footer className="footer_main">
            <div className="container-fluid">
                <div className="container">
                    <About />
                    <div className="row align-items-center br_top_1 pt-3">
                        <div className="col-md-6 copy_right">Copyright © 2020 NADJI.RS. all rights reserved</div>
                        <SocialNetworks />
                    </div>
                </div>
            </div>
        </footer>
        )
}