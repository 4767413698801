import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { ParamsOfSearch } from "../../state/reducers/filterReducer";

export interface MultiSelectOption {
    id: string,
    name: string
}

interface Props {
    name: string;
    placeholder: string;
    loading: boolean;
    options: MultiSelectOption[];
    changeSearch: Function;
    hasSelectAll: boolean
    onSelect?: (s: string) => void
}

export const AutoCompleteField: React.FC<Props> = ({ name, placeholder, loading, options, changeSearch, hasSelectAll, onSelect }: Props) => {

    const dispatch = useDispatch();
    const { changeFilterParams, loadCityParts } = bindActionCreators(actionsCreators, dispatch);
    const filterState = useSelector((state: State) => state.filter);
    const locationState = useSelector((state: State) => state.locations);

    const [params] = useSearchParams();
    const param = params.get(name);

    // options = JSON.parse('[{"id":5682,"extensivLocationId":85987644,"name":"Blok 43","city":"Beograd","quarter":"Beograd (Novi Beograd)","county":"Grad Beograd","state":"Centralna Srbija","country":"Srbija","type":"city_block"},{"id":43,"extensivLocationId":86328125,"name":"Novi Sad","city":null,"quarter":null,"county":"Južnobački upravni okrug","state":"Vojvodina","country":"Srbija","type":"city_district"}]')
    const [selectedValues, setSelectedValues] = useState<MultiSelectOption[]>([]);
    const [hideLocations, setHideLocations] = useState(true);
    
    // const [selectedValues] = useState(options.filter(value => {
    //     const selected = (param || filterState.stateOfFilter[name as keyof ParamsOfSearch])!.split(",");
    //     if (selected.indexOf(value.id) > -1) { return value };
    // }));

    useEffect(() => {
        if(selectedValues.length === 0){
            const values = options.filter(value => {
                const selected = (param || filterState.stateOfFilter[name as keyof ParamsOfSearch])!.split(",");
                if (selected.indexOf(value.id) > -1 && selectedValues.map(v => v.id).indexOf(value.id) == -1) { return value };
            });
            setSelectedValues(prevState => [...prevState, ...values])
        }
    }, [options]);

    return (
        <Multiselect
            className={hideLocations ? "hide_locations" : ""}
            onSearch={(event) => {
                setHideLocations(event.length === 0)
                changeSearch(event);
            }}
            selectionLimit={hasSelectAll === true ? 100 : 1}
            options={options}
            placeholder={placeholder}
            selectedValues={selectedValues}
            emptyRecordMsg={locationState.loading ? "Lokacije se učitavaju..." : "Nema rezultata"}
            // loading={loading}
            onSelect={(event) => {
                if (onSelect) {
                    onSelect(event[0].id)
                }
                let values = [];
                let array = Array.from(event);
                for (let index = 0; index < array.length; index++) {
                    const v = array[index] as MultiSelectOption;
                    values.push(v.id);
                }
                const stringValues = values.join(",");
                filterState.stateOfFilter[name as keyof ParamsOfSearch] = stringValues;
                if (name === "grad") {
                    loadCityParts(stringValues, "");
                }
                changeFilterParams({ stateOfFilter: filterState.stateOfFilter });
                setHideLocations(true)
            }}
            onRemove={(event) => {
                let values = [];
                let array = Array.from(event);
                for (let index = 0; index < array.length; index++) {
                    const v = array[index] as MultiSelectOption;
                    values.push(v.id);
                }
                const stringValues = values.join(",");
                filterState.stateOfFilter[name as keyof ParamsOfSearch] = stringValues;
                if (name === "grad") {
                    loadCityParts(stringValues, "");
                }
                changeFilterParams({ stateOfFilter: filterState.stateOfFilter });
            }}
            displayValue="name"
        />
    )
}

{/* <Multiselect
                onSearch={(event)=>{
                    loadCityParts(filterState.stateOfFilter.grad,event);
                }}
                options={deloviGrada}
                placeholder="Delovi grada"
                selectedValues={selectedValues}
                emptyRecordMsg = "Nema rezultata"
                onSelect={(event) =>{
                    let krajevi = [];
                    let array = Array.from(event);
                    for (let index = 0; index < array.length; index++) {
                        const kraj = array[index] as MultiSelectOption;
                        krajevi.push(kraj.id);
                    }
                    const stringKrajevi = krajevi.join(",");
                    filterState.stateOfFilter[name as keyof ParamsOfSearch] = event.target.value;
                    filterState.stateOfFilter.krajevi = stringKrajevi;
                    changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                }}
                onRemove={(event) =>{
                    let krajevi = [];
                    let array = Array.from(event);
                    for (let index = 0; index < array.length; index++) {
                        const kraj = array[index] as MultiSelectOption;
                        krajevi.push(kraj.id);
                    }
                    const stringKrajevi = krajevi.join(",");
                    filterState.stateOfFilter.krajevi = stringKrajevi;
                    changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                }}
                displayValue="name"
                /> */}