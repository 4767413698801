import { Props } from "./Listing"
import { useSelector } from "react-redux";
import { State } from "../../../state";

export const Price: React.FC<Props> = ({ item }: Props) => {

    const pricePerSquareMeter = item.area && item.price ? new Intl.NumberFormat('sr-RS', {maximumFractionDigits: 0}).format(item.price / item.area): "";

    return (
        <div className="row info-row ">
            <h5 className="height:1em;">
                {item.price == null ? "-" : item.price.toLocaleString('de-DE')} € ({<span className="price-span">{pricePerSquareMeter} €/m<sup>2</sup></span>})
            </h5>
        </div>
    )
}