import React from 'react'
import '../ListindDetailsModal/ListingModal.css'
import { Item } from '../../../../state/reducers/apartmentReducer'
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators } from '../../../../state';
import { State } from "../../../../state";
import ListingCarousel from './ListingCarousel';

export interface Props {
    item: Item,
};

export const ListingDetailsSection: React.FC = () => {
    const dispatch = useDispatch();
    const { isVisibleListingModal } = bindActionCreators(actionsCreators, dispatch);
    const modal = useSelector((state: State) => state.listingModal);

    const convertHeatingType = (heatingTypeIndex: number) => {
        const heatingTypes = [
            'Gradsko',
            'Grejanje na gas',
            'Grejanje na struju',
            'Kombinovano grejanje',
            'Grejanje na čvrsto gorivo',
            'Podno grejanje',
            'Grejanje na kaljevu peć',
            'Drugo',
            'Nije navedeno',
        ];

        if (heatingTypeIndex >= 0 && heatingTypeIndex < heatingTypes.length) {
            return heatingTypes[heatingTypeIndex];
        }

        return 'Nepoznato';
    };

    const convertFurnishingType = (furnishingTypeIndex: number) => {
        const furnishingTypes = [
            'Namešten',
            'Polunamešten',
            'Nenamešten',
            'Nije navedeno',
        ];

        if (furnishingTypeIndex >= 0 && furnishingTypeIndex < furnishingTypes.length) {
            return furnishingTypes[furnishingTypeIndex];
        }

    }

    return (
        <div className="modal-content  modal-dialog-scrollable mt-5 p-3 ">
            <div className="modal-header">
                <h4 className="modal-title">Detaljniji pregld listinga</h4>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                        modal.state.isVisibleListingModal = false;
                        isVisibleListingModal({ state: modal.state, item: modal.item });
                    }}
                >&times;</button>
            </div>
            <div className="modal-body listing-modal">
                {modal.item !== null &&
                    <>
                        <div className='listing-modal-title'>
                            <p className='title'>{modal.item.title}</p>
                        </div>
                        {
                            modal.item.pictures !== "" && <div className='picture-wrapper'>
                                <ListingCarousel images={modal.item!.pictures.split(",").map(p => p.trim()).filter(p => p !== "")} isOpen={false} ></ListingCarousel>
                            </div>
                        }
                        <div className="listing-modal-price">
                            <p>Cena: <span className='price'>{modal.item.price == null ? "-" : modal.item.price.toLocaleString('de-DE')} €</span></p>
                        </div>
                        <div className='listing-modal-info'>
                            <div className="card-header">
                                <h4>Dodatne informacije</h4>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    <strong>Mesto:</strong> {modal.item.city}
                                </p>
                                <p className="card-text">
                                    <strong>Naselje:</strong> {modal.item.cityPart}
                                </p>
                                {modal.item.floor &&
                                    <p className="card-text">
                                        <strong>Ulica: </strong>{modal.item.street}
                                    </p>
                                }
                                {modal.item.floor &&
                                    <p className="card-text">
                                        <strong>Sprat: </strong>{modal.item.floor}
                                    </p>
                                }
                                {modal.item.rooms !== null && modal.item.rooms !== 0 &&
                                    <p className="card-text">
                                        <strong>Broj soba:</strong> {modal.item.rooms}
                                    </p>
                                }
                                <p className="card-text">
                                    <strong>Površina:</strong> {modal.item.area} m2
                                </p>
                                {modal.item.airCondition &&
                                    <p className="card-text">
                                        <strong>Klima:</strong> <i className="fa fa-check-circle text-success"></i>
                                    </p>
                                }
                                {modal.item.terrace &&
                                    <p className="card-text">
                                        <strong>Terasa:</strong> <i className="fa fa-check-circle text-success"></i>
                                    </p>
                                }
                                {modal.item.elavator &&
                                    <p className="card-text">
                                        <strong>Lift:</strong> <i className="fa fa-check-circle text-success"></i>
                                    </p>
                                }
                                <p className="card-text">
                                    <strong>Tip grejanja:</strong> {convertHeatingType(parseInt(modal.item.heatingType))}
                                </p>
                                <p className="card-text">
                                    <strong>Opremljenost :</strong> {convertFurnishingType(parseInt(modal.item.furnishingType))}
                                </p>
                                {modal.item.created &&
                                    <p className="card-text">
                                        <strong>Oglas kreiran:</strong> {new Date(modal.item.created.toString()).toLocaleDateString('de-DE', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        })}
                                    </p>
                                }
                                {modal.item.updated &&
                                    <p className="card-text">
                                        <strong>Oglas ažuriran:</strong> {new Date(modal.item.updated.toString()).toLocaleDateString('de-DE', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        })}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className='listing-modal-desc'>
                            <div className="card-header">
                                <h4>Opis</h4>
                            </div>
                            <p>{modal.item.description}</p>
                        </div>
                    </>
                }
                {modal.item === null &&
                    <h1>Postoji greška prilikom dobavljanja oglasa. Pokušajte povo kasnije
                    </h1>
                }
            </div>
        </div>
    )
}

export default ListingDetailsSection