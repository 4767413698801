import { Props } from "./Listing"


export const AdDate: React.FC<Props> = ({ item }: Props) => {
    return (
        <table className="table table-sm">
            <tbody>
                <tr>
                    <th scope="row">Datum:</th>
                    <td>{item.updated == null ? 'nema informacija' : (new Date(item.updated)).toLocaleDateString('de-DE')}</td>
                </tr>
            </tbody>
        </table>
    )
}