import { Nav } from "./Nav"

export const Header = () => {
    return (
        <header className="container-fluid bg-light">
            <div className="container p-0">
                <Nav />
            </div>
        </header>
    )
}