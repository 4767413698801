import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { Option, ParamsOfSearch, StateOfFilter } from "../../state/reducers/filterReducer";

export interface Props {
    name: string;
    options: Option[]
}

export const SelectOfFilter: React.FC<Props> = ({ name, options }: Props) => {
    const dispatch = useDispatch();

    const { changeFilterParams } = bindActionCreators(actionsCreators, dispatch);

    const filterState = useSelector((state: State) => state.filter);

    const rowOption = options.map((option,index) => <option key={index} value={option.value}>{option.optionContent}</option>)

    return (
        <div id="tip" className="form-group col-lg-3 col-md-12 col-sm-12">
            <select 
                className="form-control" 
                name={name} 
                onChange={(event) => {
                    event.preventDefault();
                    filterState.stateOfFilter[name as keyof ParamsOfSearch] = event.target.value;
                    changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                }}
                value={filterState.stateOfFilter[name as keyof ParamsOfSearch]!}>
                {rowOption}
            </select>
        </div>
        )
}