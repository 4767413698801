import axios from "axios";
import { Item, ListingMark } from "../state/reducers/apartmentReducer";



export const markListing = (listingMark: ListingMark) => {
    const token = localStorage.getItem('loggedUser') !== null ? JSON.parse(localStorage.getItem('loggedUser')!).token : null;
    var conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Listings/mark`,
        method: "PUT",
        headers: {
            'Authorization': "Bearer " + token
        },
        data: listingMark
    };
    return axios.request(conf);
}

export const addListing = (listing: Item) => {
    const token = localStorage.getItem('loggedUser') !== null ? JSON.parse(localStorage.getItem('loggedUser')!).token : null;
    var conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Listings/`,
        method: "POST",
        headers: { 'Authorization': "Bearer " + token },
        data: listing
    };
    return axios.request(conf);
}

export const updateListing = (listing: Item) => {
    const token = localStorage.getItem('loggedUser') !== null ? JSON.parse(localStorage.getItem('loggedUser')!).token : null;
    var conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Listings/`,
        method: "PUT",
        headers: { 'Authorization': "Bearer " + token },
        data: listing
    };
    return axios.request(conf);
}

export const GetById = (id: number) => {
    const token = localStorage.getItem('loggedUser') !== null ? JSON.parse(localStorage.getItem('loggedUser')!).token : null;
    var conf = {
        baseURL: `${process.env.REACT_APP_API_URL}Listings/${id}`,
        method: "GET",
        headers: { 'Authorization': "Bearer " + token }
    };
    return axios.request(conf);
}