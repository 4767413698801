import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: UserState = {
    state:{
        logged : 
        {
            email:"",
            password:""
        },
        register:{
            email:"",
            password:""
        }
    }
};

export interface UserState {
    state : {
        logged:User,
        register:User
    }
}

export interface User {
    email:string,
    password:string
}

const loadingReducer = (state: UserState = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.CHNAGE_USER_STATE:
            return action.payload;
        default:
            const user = JSON.parse(localStorage.getItem("loggedUser")!);
            if(user!=null && user.expires > (new Date().getTime()/1000)){
                state.state.logged.email = user.email;
            }
            if(user!=null && user.expires < (new Date().getTime()/1000)){
                localStorage.clear();
            }
            return state;
    }
}

export default loadingReducer;